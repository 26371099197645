import { Link } from 'react-router-dom'
import { Text, TableConfig } from 'src/components/ui'
import { humanDateTime } from 'src/utility/time'
import { GatewayVersionRenderer } from 'gateway/components'

export const gatewaysTableConfig = (
  onUpgradeClick: () => void,
  orgId: string,
  siteId: string,
  latestVersion?: string,
): TableConfig => {
  return {
    allowSorting: true,
    columns: [
      {
        field: 'id',
        headerName: 'Gateway ID',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <Link
            to={`/settings/orgs/${orgId}/sites/${siteId}/gateways/${value}`}
            className="text-text-brand no-underline"
          >
            <Text bold variant="code">
              {value}
            </Text>
          </Link>
        ),
      },
      {
        field: 'hostname',
        headerName: 'Location',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{value}</Text>
        ),
      },
      {
        field: 'createdOn',
        headerName: 'Created On',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{humanDateTime(value)}</Text>
        ),
      },
      {
        field: 'startTime',
        headerName: 'Start Time',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{humanDateTime(value)}</Text>
        ),
      },
      {
        field: 'lastPing',
        headerName: 'Last Heartbeat',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{humanDateTime(value)}</Text>
        ),
      },
      {
        field: 'version',
        colId: 'actions',
        headerName: 'Version',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <GatewayVersionRenderer
            version={value}
            latestVersion={latestVersion}
            onUpgradeClick={onUpgradeClick}
          />
        ),
      },
    ],
  }
}
